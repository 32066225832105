"use client";

import React from "react";
import { DarkLayout } from "./DarkLayout";
import { LightLayout } from "./LightLayout";
import { useTheme } from "next-themes";

const ThemeComp = ({ children }) => {
  // const { theme } = useTheme();
  // console.log("🚀 ~ src/app/LayoutComp.tsx:9 ~ theme: ", theme);

return <DarkLayout>{children}</DarkLayout>


  return (
    <>
      {theme === "dark" || (!theme && <DarkLayout>{children} </DarkLayout>)}
      {theme === "light" && <LightLayout>{children} </LightLayout>}
    </>
  );
};

export default ThemeComp;
