"use client";

import { FloatNav } from "@/components/dock/FloatNav";
import React from "react";
import { Analytics } from "@/components/Analytics";
import dynamic from "next/dynamic";
const Background = dynamic(() => import("@/components/Background"), {
  ssr: false,
});

export const DarkLayout = ({ children }) => {
  return (
    <>
      <Background>
        <>
          <div className="relative">
            <main>{children}</main>
            <div className="relative mt-20 ">
              <div className="fixed inset-x-0 bottom-0 isolate z-[10] h-[100px]">
                <div className="gradient-mask-t-0 absolute inset-0 backdrop-blur-[1px]"></div>
                <div className="gradient-mask-t-0 absolute inset-0 backdrop-blur-[2px]"></div>
                <div className="gradient-mask-t-0 absolute inset-0 backdrop-blur-[3px]"></div>
                <div className="gradient-mask-t-0 absolute inset-0 backdrop-blur-[6px]"></div>
                <div className="gradient-mask-t-0 absolute inset-0 backdrop-blur-[12px]"></div>
              </div>
            </div>
            <FloatNav />
          </div>
          <Analytics />
        </>
      </Background>
    </>
  );
};
