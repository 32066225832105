"use client";

import Image from "next/image";
import React, { useState, useEffect, ReactNode } from "react";

export const PreLoader = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (document.readyState === "complete") {
      setTimeout(() => setIsLoading(false), 3000);
    } else {
      window.addEventListener("load", () => setIsLoading(false));
    }

    return () => {};
  }, []);

  return (
    <>
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-center">
          <div className="flex flex-col items-center">
            <div className="mt-10 loading"></div>
            <img
              width={400}
              height={400}
              alt="Ammad"
              src="https://github.com/user-attachments/assets/834c16cf-edae-41ea-af1e-37837ee98c3d#gh-dark-mode-only"
            />
            <div className="mt-10 loader"></div>
          </div>
        </div>
      )}
      {!isLoading && <>{children}</>}
    </>
  );
};
