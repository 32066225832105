import { Trophy } from 'lucide-react'
import {
  Album,
  BookOpen,
  Code,
  Github,
  Instagram,
  Layers,
  Linkedin,
  Mail,
  Scroll,
  Twitter,
  User,
} from 'lucide-react'

export const generalLinks = [
  {
    href: '/',
    label: 'Home',
    icon: 'Avatar',
  },
  {
    href: '/about',
    label: 'About',
    icon: User,
  },
  {
    href: '/articles',
    label: 'Articles',
    icon: BookOpen,
  },
  {
    href: '/projects',
    label: 'Projects',
    icon: Code,
  },
  {
    href: '/stack',
    label: 'Stack',
    icon: Layers,
  },
  {
    href: '/gallery',
    label: 'Gallery',
    icon: Album,
  },

  {
    href: 'https://github.com/hi-ammad',
    label: 'Github',
    target: '_blank',
    icon: Github,
  },

  {
    href: 'https://linkedin.com/in/hi-ammad',
    label: 'Linkedin',
    target: '_blank',
    icon: Linkedin,
  },

  {
    href: 'https://credly.com/user/hi-ammad',
    label: 'Credly',
    target: '_blank',
    icon: Trophy,
  },


]

export const linksSocial = [
  {
    href: 'https://twitter.com/hi-ammad',
    icon: Twitter,
    label: 'Twitter',
    outline: true,
  },
  {
    href: 'https://instagram.com/hi-ammad/',
    icon: Instagram,
    label: 'Instagram',
    outline: true,
  },
  {
    href: 'https://github.com/hi-ammad',
    icon: Github,
    label: 'GitHub',
    outline: true,
  },
  {
    href: 'https://linkedin.com/in/hi-ammad',
    icon: Linkedin,
    label: 'LinkedIn',
    outline: true,
  },
  {
    href: 'https://read.cv/hi_ammad',
    icon: Scroll,
    label: 'Read.cv',
    outline: true,
  },
  {
    href: 'mailto:hello@ammad.dev',
    icon: Mail,
    label: 'Mail',
    outline: true,
  },
]
